import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { Section, Title, Text, Box, ButtonIcon } from '../../components/Core'
import PageWrapper from '../../components/PageWrapper'
import Screenshot from '../../components/Screenshot'
import NextLink from '../../components/NextLink'
import WebsiteLink from '../../components/WebsiteLink'
import Slides from '../../components/Slides'
import Contact from '../../sections/common/Contact'
import Hero from '../../sections/common/Hero'
import { Keys, Key } from '../../sections/keys/Keys'

import favicon from '../../assets/images/aa/favicon.png'
import matchdocs from '../../assets/images/pd/match-doctors.png'

import homepage from '../../assets/images/ajc/homepage.jpg'
import algoliasearch from '../../assets/images/ajc/algolia-search.jpg'
import doctorprofiles from '../../assets/images/ajc/doctor-profile.jpg'
import footankle from '../../assets/images/ajc/foot-ankle.jpg'
import jointevaluationq from '../../assets/images/ajc/joint-evaluation-question.jpg'
import jointevaluation from '../../assets/images/ajc/joint-evaluation.jpg'
import videos from '../../assets/images/ajc/videos.jpg'

const WorkSingle = () => {
    return (
        <>
            <PageWrapper>
                <Hero className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="8">
                                <Text variant="tag">Personal</Text>
                                <Title mb="0.5em">React Three Fiber</Title>
                                <Text variant="p" mb="1.6em">
                                    My passion for computers and technology
                                    started along time ago on a Commodore 64
                                    that my mother purchased for me as a young
                                    12yr old.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    This early induction into computing led me
                                    into computer gaming at an early age.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    This passion for computing and gaming
                                    attracted me to React Three Fiber.
                                </Text>
                            </Col>
                            <Col lg="4">
                                <img
                                    src={matchdocs}
                                    alt="Website Analytics"
                                    className="img-fluid w-100"
                                />
                            </Col>
                        </Row>
                    </Container>
                </Hero>

                <div className="mt-lg-3">
                    <Container>
                        <Row>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Client</Text>
                                <Title variant="cardBig" className="mt-3">
                                    Personal
                                </Title>
                            </Col>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Time</Text>
                                <Title variant="cardBig" className="mt-3">
                                    2020
                                </Title>
                            </Col>
                            <Col lg="4">
                                <Text variant="tag">Website</Text>

                                <Title variant="cardBig" className="mt-3">
                                    <WebsiteLink
                                        src={favicon}
                                        href="https://www.atlanticjointcenter.com"
                                    >
                                        atlanticjointcenter.com
                                    </WebsiteLink>
                                </Title>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Section className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="5" className="mb-5 pr-lg-5">
                                <img
                                    src={matchdocs}
                                    alt="Match Docs"
                                    className="img-fluid w-100"
                                />
                            </Col>
                            <Col lg="7" className="mb-5 pl-lg-5">
                                <Title variant="cardBig" className="mb-4">
                                    Building A Minecraft Clone
                                </Title>
                                <Text variant="p" mb="1.6em">
                                    I wanted to use my mastery of react to build
                                    something fun.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    I found a tutorial on building a Minecraft
                                    clone using only react and react three
                                    fiber.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    The tutorial led me to applying several
                                    updates, particularly the optimization of
                                    react rendering.
                                </Text>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Keys mt="-5%">
                    <Key name="react" title="01. React" left>
                        I love react. The only thing react is missing is the
                        ability to render 3d animations. Combined with React
                        Three Fiber, react makes the perfect partner to generate
                        3 dimensional worlds right on a standard browser.
                    </Key>

                    <Key name="algolia" title="02. React Three Fiber">
                        This project was my first experience with 3d rendering.
                        I have been a UI coder for over 20 years and I thought
                        it was about time I dipped my toes in this wonderful new
                        world of rendering web interfaces.
                    </Key>

                    <Key name="googlecloud" title="03. Google Cloud" left>
                        Hosting progressive web applications is incredible
                        simple using Google Clouds App Engine. 1 config file and
                        the application is up and running on the cloud.
                    </Key>

                    <Key name="cannon" title="04. Cannon">
                        Behind every great 3d engine is a physics engine. I
                        needed a physics engine to control all aspects of a 3
                        dimensial world like this.
                    </Key>
                </Keys>

                <Section mt="22%" bg="dark">
                    <Container>
                        <Row
                            css={`
                                margin-top: -35%;
                            `}
                        >
                            <Col xs="12" className="mb-5">
                                <Slides>
                                    <Screenshot
                                        src={homepage}
                                        alt="Atlantic Joint Center Homepage"
                                    />
                                    <Screenshot
                                        src={doctorprofiles}
                                        alt="Doctor Profiles"
                                    />
                                    <Screenshot
                                        src={algoliasearch}
                                        alt="Algolia Search"
                                    />
                                    <Screenshot
                                        src={jointevaluation}
                                        alt="Joint Evaluation"
                                    />
                                    <Screenshot
                                        src={jointevaluationq}
                                        alt="Joint Evaluation Question"
                                    />
                                    <Screenshot
                                        src={footankle}
                                        alt="Foot and Ankle Landing Page"
                                    />
                                    <Screenshot
                                        src={videos}
                                        alt="Joint Pain Animation Videos"
                                    />
                                </Slides>
                            </Col>
                        </Row>

                        <div className="text-center mt-lg-5">
                            <Text
                                variant="tag"
                                className="mb-1"
                                color="lightShade"
                            >
                                Next Project
                            </Text>
                            <NextLink to="/works/swimming-pool/">
                                Swimming Pool Rebuild
                            </NextLink>
                        </div>
                    </Container>
                </Section>

                <Box py={4}>
                    <Contact />
                </Box>
            </PageWrapper>
        </>
    )
}
export default WorkSingle
